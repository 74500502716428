import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'odd_pay_payment_infos',
  attributes: [
    'id',
    'aasm_state',
    'amount',
    'payment_type',
    'async_payment_info'
  ],
  editableAttributes: []
}

export default class OddPayPaymentInfo extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
