import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('payment_methods')

export default {
  [types.FETCH_PAYMENT_METHODS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_PAYMENT_METHODS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_PAYMENT_METHOD_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_PAYMENT_METHOD_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_PAYMENT_METHOD_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_PAYMENT_METHOD_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.FETCH_GATEWAYS_SUCCESS](state, response) {
    state.gateways = response.data.data
    state.isCallingAPI = false
  },

  [types.FETCH_INVOICE_SERVICES_SUCCESS](state, response) {
    state.invoiceServices = response.data.data
    state.isCallingAPI = false
  },

  [types.FETCH_INVOICE_FOR_PAYMENT_CONFIG_SUCCESS](state, response) {
    state.isProvideInvoiceForPayment =
      response.data.data.is_provide_invoice_for_payment
    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
