import OddPayInvoice from '@models/odd_pay/invoice'
import OddPayPaymentInfo from '@models/odd_pay/payment_info'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new OddPayInvoice(state.entities[id]))
}

export const find = (state) => (id) => {
  return new OddPayInvoice(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const currentNormalInvoice = (state) => {
  return find(state)(state.currentNormalInvoiceId)
}

export const currentSubscriptionInvoice = (state) => {
  return find(state)(state.currentSubscriptionInvoiceId)
}

export const allPaymentInfos = (state) => {
  return state.paymentInfoResult.map(
    (id) => new OddPayPaymentInfo(state.paymentInfoEntities[id])
  )
}
