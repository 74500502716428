import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'odd_pay_invoices',
  attributes: [
    'id',
    'number',
    'created_at',
    'updated_at',
    'email',
    'contact_phone',
    'note',
    'name',
    'company_name',
    'company_ein',
    'address',
    'items',
    'amount',
    'payments',
    'notifications',
    'subscription_info',
    'paid_at',
    'expired_at',
    'buyer_type',
    'buyer_id',
    'invoice_type',
    'completed_at',
    'invoice_state',
    'payment_state',
    'unpaid_amount',
    'reserved_amount'
  ],
  editableAttributes: [
    'email',
    'name',
    'contact_phone',
    'company_name',
    'company_ein',
    'address',
    'note',
    'payment_method_id',
    'variant_id',
    'quantity'
  ]
}

export default class OddPayInvoice extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  apiBasePath(options = {}) {
    let result = `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}`

    result += `/odd_pay/invoices`
    return result
  }

  // extra methods or helpers here...
  get invoiceStateType() {
    return {
      'is-success': this.invoice_state === 'completed',
      'is-danger': ['canceled'].includes(this.invoice_state)
    }
  }

  get paymentStateType() {
    return {
      'is-default': this.payment_state === 'checkout',
      'is-success': this.payment_state === 'paid',
      'is-danger': ['overdue', 'void'].includes(this.payment_state)
    }
  }

  get canMakePayment() {
    return (
      this.invoice_state === 'completed' &&
      ['checkout', 'balance_due'].includes(this.payment_state) &&
      this.unpaid_amount - this.reserved_amount > 0
    )
  }

  get fullAddress() {
    return [
      this.address.zip,
      this.address.city,
      this.address.area,
      this.address.street_address
    ].join(' ')
  }
}
