export const FETCH_MEMBER_LEVELS_SUCCESS = 'FETCH_MEMBER_LEVELS_SUCCESS'
export const GET_RELATED_MEMBER_LEVELS_SUCCESS =
  'GET_RELATED_MEMBER_LEVELS_SUCCESS'
export const GET_MEMBER_LEVELS_SUCCESS = 'GET_MEMBER_LEVELS_SUCCESS'
export const ADD_MEMBER_LEVELS_SUCCESS = 'ADD_MEMBER_LEVELS_SUCCESS'
export const UPDATE_MEMBER_LEVELS_SUCCESS = 'UPDATE_MEMBER_LEVELS_SUCCESS'
export const DELETE_MEMBER_LEVELS_SUCCESS = 'DELETE_MEMBER_LEVELS_SUCCESS'
export const FETCH_AVAILABLE_OPTIONS_SUCCESS = 'FETCH_AVAILABLE_OPTIONS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
