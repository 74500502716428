import * as types from './mutation-types'
import MutationHelpers from '@storeModules/mutation_helpers'

const helpers = new MutationHelpers('odd_pay_invoices')

export default {
  [types.FETCH_ODD_PAY_INVOICES_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_ODD_PAY_INVOICES_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_ODD_PAY_INVOICE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.replaceSpecialEntities(
      state,
      response,
      'paymentInfo',
      'odd_pay_payment_infos'
    )

    state.isCallingAPI = false
  },

  [types.ADD_ODD_PAY_INVOICE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_ODD_PAY_INVOICE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_ODD_PAY_INVOICE_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  },

  [types.GET_CURRENT_NORMAL_ODD_PAY_INVOICE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    state.currentNormalInvoiceId = response.data.data.id

    state.isCallingAPI = false
  },

  [types.GET_CURRENT_SUBSCRIPTION_ODD_PAY_INVOICE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    state.currentSubscriptionInvoiceId = response.data.data.id

    state.isCallingAPI = false
  }
}
