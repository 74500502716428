export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS'
export const FETCH_CONTENTS_FOR_SEARCH_SUCCESS =
  'FETCH_CONTENTS_FOR_SEARCH_SUCCESS'
export const GET_RELATED_CONTENTS_SUCCESS = 'GET_RELATED_CONTENTS_SUCCESS'
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS'
export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS'
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS'
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
