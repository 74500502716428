export const FETCH_PRODUCT_TAGS_SUCCESS = 'FETCH_PRODUCT_TAGS_SUCCESS'
export const GET_RELATED_PRODUCT_TAGS_SUCCESS =
  'GET_RELATED_PRODUCT_TAGS_SUCCESS'
export const GET_PRODUCT_TAG_SUCCESS = 'GET_PRODUCT_TAG_SUCCESS'
export const ADD_PRODUCT_TAG_SUCCESS = 'ADD_PRODUCT_TAG_SUCCESS'
export const UPDATE_PRODUCT_TAG_SUCCESS = 'UPDATE_PRODUCT_TAG_SUCCESS'
export const DELETE_PRODUCT_TAG_SUCCESS = 'DELETE_PRODUCT_TAG_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
