export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS'
export const GET_RELATED_BRANDS_SUCCESS = 'GET_RELATED_BRANDS_SUCCESS'
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS'
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS'
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS'
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS'
export const FETCH_BRAND_PRODUCTS_SUCCESS = 'FETCH_BRAND_PRODUCTS_SUCCESS'
export const UPLOAD_ATTACHMENTS_SUCCESS = 'UPLOAD_ATTACHMENTS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
