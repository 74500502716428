import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'return_authorization_items',
  attributes: [
    'id',
    'amount',
    'is_resellable',
    'reception_state',
    'acceptance_state',
    'apply_quantity',
    'return_authorization',
    'reimbursement',
    'shipment_item',
    'variant',
    'exchange_variant',
    'created_at',
    'updated_at',
    'edit_records',
    'activities',
    'returned_quantity'
  ],
  editableAttributes: [
    'new_amount',
    'is_resellable',
    'edit_note',
    'returned_quantity'
  ]
}

export default class ReturnAuthorizationItem extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  findOrderItem() {
    return axios.get(`${this.apiBasePath()}/${this.id}/order_item`)
  }

  fetchAllOrderAdjustments() {
    return axios.get(`${this.apiBasePath()}/${this.id}/all_order_adjustments`)
  }

  editAmount() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/edit_amount`,
      this.requestBody()
    )
  }

  accept() {
    return axios.put(`${this.apiBasePath()}/${this.id}/accept`)
  }

  reject() {
    return axios.put(`${this.apiBasePath()}/${this.id}/reject`)
  }

  giveToCustomer() {
    return axios.put(`${this.apiBasePath()}/${this.id}/give_to_customer`)
  }

  receive() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/receive`,
      this.requestBody()
    )
  }

  // extra methods or helpers here...
  get acceptanceStateType() {
    return {
      'is-success': this.acceptance_state === 'accepted',
      'is-warning': this.mayAccept,
      'is-danger': this.acceptance_state === 'rejected'
    }
  }

  get receptionStateType() {
    return {
      'is-success': ['received', 'given_to_customer'].includes(
        this.reception_state
      ),
      'is-warning': this.reception_state === 'waiting',
      'is-danger': this.reception_state === 'canceled'
    }
  }

  get isCanceled() {
    return this.reception_state === 'canceled'
  }

  get isEditable() {
    return !this.isCanceled && !this.isReimbursed
  }

  get mayAccept() {
    return (
      this.reception_state === 'waiting' &&
      ['pending', 'human_intervention_required'].includes(this.acceptance_state)
    )
  }

  get mayReject() {
    return (
      this.reception_state === 'waiting' && this.acceptance_state !== 'rejected'
    )
  }

  get mayReceive() {
    return (
      this.acceptance_state === 'accepted' && this.reception_state === 'waiting'
    )
  }

  get mayGiveToCustomer() {
    return this.mayReceive
  }

  get isReimbursed() {
    return Object.keys(this.reimbursement).length > 0
  }
}
