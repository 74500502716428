import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_bonus_credit_transactions',
  attributes: [
    'id',
    'reason',
    'credit_amount',
    'credit_balance',
    'order_number',
    'order_token',
    'created_at'
  ],
  editableAttributes: ['credit_amount', 'reason']
}

export default class UserBonusCreditTransaction extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
