import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'add_on_items',
  attributes: [
    'id',
    'created_at',
    'updated_at',
    'product_name',
    'product',
    'is_available',
    'add_on_group_id'
  ],
  editableAttributes: []
}

export default class AddOnItem extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  apiBasePath(options = {}) {
    let result = `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}`

    result += `/add_on/items`
    return result
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }
}
