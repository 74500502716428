export const FETCH_SALES_EVENTS_SUCCESS = 'FETCH_SALES_EVENTS_SUCCESS'
export const FETCH_SALES_EVENTS_FOR_SEARCH_SUCCESS =
  'FETCH_SALES_EVENTS_FOR_SEARCH_SUCCESS'
export const GET_RELATED_SALES_EVENTS_SUCCESS =
  'GET_RELATED_SALES_EVENTS_SUCCESS'
export const GET_SALES_EVENT_SUCCESS = 'GET_SALES_EVENT_SUCCESS'
export const ADD_SALES_EVENT_SUCCESS = 'ADD_SALES_EVENT_SUCCESS'
export const UPDATE_SALES_EVENT_SUCCESS = 'UPDATE_SALES_EVENT_SUCCESS'
export const DELETE_SALES_EVENT_SUCCESS = 'DELETE_SALES_EVENT_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
