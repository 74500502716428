import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'distribution_base_locations',
  attributes: ['id', 'name', 'distribution_bases_count'],
  editableAttributes: ['name']
}

export default class DistributionBaseLocation extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
