import Content from '../../resource_models/content'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new Content(state.entities[id]))
}

export const find = (state) => (id) => {
  return new Content(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

/**
 * 回傳 API response 中的 searchMeta 資訊
 *
 * @returns {object} searchMeta info from API response
 */
export const searchMeta = (state) => {
  return state.searchMeta
}

export const errors = (state) => {
  return state.errors
}
