import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'odd_pay_payment_methods',
  attributes: [
    'id',
    'name',
    'description',
    'payment_type',
    'enabled',
    'created_at',
    'payment_gateway_id',
    'usages'
  ],
  editableAttributes: [
    'name',
    'description',
    'payment_type',
    'enabled',
    'usages'
  ]
}

export default class OddPayPaymentMethod extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchUsageScopeInfo() {
    return axios.get(`${new this().apiBasePath()}/usage_scope_info`)
  }

  apiBasePath(options = {}) {
    let result = `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}`

    result += `/odd_pay/payment_methods`
    return result
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  // extra methods or helpers here...
}
