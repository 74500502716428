export const FETCH_ADD_ON_GROUPS_SUCCESS = 'FETCH_ADD_ON_GROUPS_SUCCESS'
export const FETCH_ADD_ON_GROUPS_FOR_SEARCH_SUCCESS =
  'FETCH_ADD_ON_GROUPS_FOR_SEARCH_SUCCESS'
export const GET_RELATED_ADD_ON_GROUPS_SUCCESS =
  'GET_RELATED_ADD_ON_GROUPS_SUCCESS'
export const GET_ADD_ON_GROUP_SUCCESS = 'GET_ADD_ON_GROUP_SUCCESS'
export const ADD_ADD_ON_GROUP_SUCCESS = 'ADD_ADD_ON_GROUP_SUCCESS'
export const UPDATE_ADD_ON_GROUP_SUCCESS = 'UPDATE_ADD_ON_GROUP_SUCCESS'
export const DELETE_ADD_ON_GROUP_SUCCESS = 'DELETE_ADD_ON_GROUP_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
