export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS'
export const GET_RELATED_BANNERS_SUCCESS = 'GET_RELATED_BANNERS_SUCCESS'
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS'
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS'
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS'
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS'
export const FETCH_BANNER_USE_TYPES_SUCCESS = 'FETCH_BANNER_USE_TYPES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
