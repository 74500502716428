export const FETCH_PROMOTIONAL_ARTICLES_SUCCESS =
  'FETCH_PROMOTIONAL_ARTICLES_SUCCESS'
export const FETCH_PROMOTIONAL_ARTICLES_FOR_SEARCH_SUCCESS =
  'FETCH_PROMOTIONAL_ARTICLES_FOR_SEARCH_SUCCESS'
export const GET_RELATED_PROMOTIONAL_ARTICLES_SUCCESS =
  'GET_RELATED_PROMOTIONAL_ARTICLES_SUCCESS'
export const GET_PROMOTIONAL_ARTICLE_SUCCESS = 'GET_PROMOTIONAL_ARTICLE_SUCCESS'
export const ADD_PROMOTIONAL_ARTICLE_SUCCESS = 'ADD_PROMOTIONAL_ARTICLE_SUCCESS'
export const UPDATE_PROMOTIONAL_ARTICLE_SUCCESS =
  'UPDATE_PROMOTIONAL_ARTICLE_SUCCESS'
export const DELETE_PROMOTIONAL_ARTICLE_SUCCESS =
  'DELETE_PROMOTIONAL_ARTICLE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
