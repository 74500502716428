export const FETCH_ORDER_PAYMENTS_SUCCESS = 'FETCH_ORDER_PAYMENTS_SUCCESS'
export const GET_RELATED_ORDER_PAYMENTS_SUCCESS =
  'GET_RELATED_ORDER_PAYMENTS_SUCCESS'
export const GET_ORDER_PAYMENT_SUCCESS = 'GET_ORDER_PAYMENT_SUCCESS'
export const ADD_ORDER_PAYMENT_SUCCESS = 'ADD_ORDER_PAYMENT_SUCCESS'
export const UPDATE_ORDER_PAYMENT_SUCCESS = 'UPDATE_ORDER_PAYMENT_SUCCESS'
export const DELETE_ORDER_PAYMENT_SUCCESS = 'DELETE_ORDER_PAYMENT_SUCCESS'
export const PROCESS_ONSITE_PAYMENT_SUCCESS = 'PROCESS_ONSITE_PAYMENT_SUCCESS'
export const GET_ORDER_PAYMENT_EXISTING_METHOD_NAMES_SUCCESS =
  'GET_ORDER_PAYMENT_EXISTING_METHOD_NAMES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
