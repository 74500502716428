import UserBonusCreditTransaction from '../../resource_models/user_bonus_credit_transaction'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new UserBonusCreditTransaction(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new UserBonusCreditTransaction(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
