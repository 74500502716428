export const FETCH_ADD_ON_ITEM_PRICES_SUCCESS =
  'FETCH_ADD_ON_ITEM_PRICES_SUCCESS'
export const GET_RELATED_ADD_ON_ITEM_PRICES_SUCCESS =
  'GET_RELATED_ADD_ON_ITEM_PRICES_SUCCESS'
export const GET_ADD_ON_ITEM_PRICE_SUCCESS = 'GET_ADD_ON_ITEM_PRICE_SUCCESS'
export const ADD_ADD_ON_ITEM_PRICE_SUCCESS = 'ADD_ADD_ON_ITEM_PRICE_SUCCESS'
export const UPDATE_ADD_ON_ITEM_PRICE_SUCCESS =
  'UPDATE_ADD_ON_ITEM_PRICE_SUCCESS'
export const DELETE_ADD_ON_ITEM_PRICE_SUCCESS =
  'DELETE_ADD_ON_ITEM_PRICE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
