import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('return_authorizations')

export default {
  [types.FETCH_RETURN_AUTHORIZATIONS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_RETURN_AUTHORIZATIONS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RETURN_AUTHORIZATION_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.replaceSpecialEntities(
      state,
      response,
      'item',
      'return_authorization_items'
    )

    state.isCallingAPI = false
  },

  [types.ADD_RETURN_AUTHORIZATION_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_RETURN_AUTHORIZATION_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.replaceSpecialEntities(
      state,
      response,
      'item',
      'return_authorization_items'
    )

    state.isCallingAPI = false
  },

  [types.DELETE_RETURN_AUTHORIZATION_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.FETCH_RETURN_AUTHORIZATION_ITEMS_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(
      state,
      response,
      'item',
      'return_authorization_items'
    )

    state.isCallingAPI = false
  },

  [types.UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(
      state,
      response,
      'item',
      'return_authorization_items'
    )

    state.isCallingAPI = false
  },

  [types.FETCH_RETURN_AUTHORIZATION_REASONS_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(
      state,
      response,
      'reason',
      'return_authorization_reasons'
    )

    state.isCallingAPI = false
  },

  [types.ADD_RETURN_AUTHORIZATION_REASON_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(
      state,
      response,
      'reason',
      'return_authorization_reasons'
    )
    helpers.pushResourceToSpecialResult(
      state,
      response,
      'reason',
      'return_authorization_reasons'
    )

    state.isCallingAPI = false
  },

  [types.UPDATE_RETURN_AUTHORIZATION_REASON_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(
      state,
      response,
      'reason',
      'return_authorization_reasons'
    )

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
