import * as types from './mutation-types'
import PaymentMethod from '../../resource_models/payment_method'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PaymentMethod.all(options)
      .then((response) => {
        commit(types.FETCH_PAYMENT_METHODS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PaymentMethod.find(id)
      .then((response) => {
        commit(types.GET_PAYMENT_METHOD_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PAYMENT_METHOD_SUCCESS, response)
        } else {
          commit(types.UPDATE_PAYMENT_METHOD_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PAYMENT_METHOD_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggle = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggle')

  return new Promise((resolve, reject) => {
    model
      .toggle()
      .then((response) => {
        commit(types.UPDATE_PAYMENT_METHOD_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggle,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchGateways = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchGateways')

  return new Promise((resolve, reject) => {
    PaymentMethod.fetchGateways()
      .then((response) => {
        commit(types.FETCH_GATEWAYS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchGateways,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const configGateway = ({ dispatch, commit }, gateway) => {
  commit(types.API_REQUEST_START, 'configGateway')

  return new Promise((resolve, reject) => {
    PaymentMethod.configGateway(gateway)
      .then((response) => {
        commit(types.FETCH_GATEWAYS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: configGateway,
            ref: {
              dispatch,
              commit
            },
            params: gateway
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const removeGatewayConfigurations = ({ dispatch, commit }, gateway) => {
  commit(types.API_REQUEST_START, 'removeGatewayConfigurations')

  return new Promise((resolve, reject) => {
    PaymentMethod.removeGatewayConfigurations(gateway)
      .then((response) => {
        commit(types.FETCH_GATEWAYS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeGatewayConfigurations,
            ref: {
              dispatch,
              commit
            },
            params: gateway
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchInvoiceServices = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchInvoiceServices')

  return new Promise((resolve, reject) => {
    PaymentMethod.fetchInvoiceServices()
      .then((response) => {
        commit(types.FETCH_INVOICE_SERVICES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchInvoiceServices,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const configInvoiceService = ({ dispatch, commit }, service) => {
  commit(types.API_REQUEST_START, 'configInvoiceService')

  return new Promise((resolve, reject) => {
    PaymentMethod.configInvoiceService(service)
      .then((response) => {
        commit(types.FETCH_INVOICE_SERVICES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: configInvoiceService,
            ref: {
              dispatch,
              commit
            },
            params: service
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const removeInvoiceServiceConfigurations = (
  { dispatch, commit },
  service
) => {
  commit(types.API_REQUEST_START, 'removeInvoiceServiceConfigurations')

  return new Promise((resolve, reject) => {
    PaymentMethod.removeInvoiceServiceConfigurations(service)
      .then((response) => {
        commit(types.FETCH_INVOICE_SERVICES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeInvoiceServiceConfigurations,
            ref: {
              dispatch,
              commit
            },
            params: service
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchInvoiceForPaymentConfig = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchInvoiceForPaymentConfig')

  return new Promise((resolve, reject) => {
    PaymentMethod.fetchInvoiceForPaymentConfig()
      .then((response) => {
        commit(types.FETCH_INVOICE_FOR_PAYMENT_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchInvoiceForPaymentConfig,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleInvoiceForPaymentConfig = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'toggleInvoiceForPaymentConfig')

  return new Promise((resolve, reject) => {
    PaymentMethod.toggleInvoiceForPaymentConfig()
      .then((response) => {
        commit(types.FETCH_INVOICE_FOR_PAYMENT_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleInvoiceForPaymentConfig,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PAYMENT_METHODS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PAYMENT_METHOD_SUCCESS, response)

    resolve(response)
  })
}
