import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import moment from 'moment'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'modal_links',
  attributes: [
    'id',
    'position',
    'use_type',
    'alt_text',
    'url',
    'image',
    'mobile_image',
    'version',
    'rules',
    'created_at',
    'updated_at',
    'started_at',
    'ended_at',
    'is_available'
  ],
  editableAttributes: [
    'use_type',
    'alt_text',
    'url',
    'image',
    'mobile_image',
    'rules',
    'started_at',
    'ended_at'
  ]
}

export default class ModalLink extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static useTypes() {
    return axios.get(`${new this().apiBasePath()}/use_type`)
  }

  static allByUseType(options) {
    return axios.get(
      `${new this().apiBasePath()}?${FetchingDataOptionsService.call(options)}`
    )
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  /**
   * Banner 使用 form data 傳遞資料，因此重新定義 `#save`
   *
   * @param {FormData} formData 內容包含 `modal_link[image]`, `modal_link[alt_text]`
   * @returns {Promise} 回傳 response 或 errors
   */
  save(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }
  // extra methods or helpers here...

  get isLive() {
    if (!this.is_available) return false
    const currentTime = moment()

    if (!this.started_at && !this.ended_at) return true

    if (this.started_at && !this.ended_at) {
      return currentTime.isAfter(moment.unix(this.started_at))
    }

    if (!this.started_at && this.ended_at) {
      return currentTime.isBefore(moment.unix(this.ended_at))
    }

    if (this.started_at && this.ended_at) {
      return currentTime.isBetween(
        moment.unix(this.started_at),
        moment.unix(this.ended_at)
      )
    }
  }

  get title() {
    return this.alt_text
  }
}
