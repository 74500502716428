import * as types from './mutation-types'
import MutationHelpers from '@storeModules/mutation_helpers'

const helpers = new MutationHelpers('odd_pay_payment_methods')

export default {
  [types.FETCH_ODD_PAY_PAYMENT_METHODS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_ODD_PAY_PAYMENT_METHODS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_ODD_PAY_PAYMENT_METHOD_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_ODD_PAY_PAYMENT_METHOD_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_ODD_PAY_PAYMENT_METHOD_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_ODD_PAY_PAYMENT_METHOD_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.FETCH_ODD_PAY_PAYMENT_METHOD_USAGE_SCOPE_INFO_SUCCESS](
    state,
    response
  ) {
    state.usageScopeInfo = response.data

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
