import MemberLevel from '@models/member_level'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new MemberLevel(state.entities[id]))
}

export const find = (state) => (id) => {
  return new MemberLevel(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const availableOptions = (state) => {
  return state.availableOptions
}
