import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('distribution_bases')

export default {
  [types.FETCH_DISTRIBUTION_BASES_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_DISTRIBUTION_BASES_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_DISTRIBUTION_BASE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_DISTRIBUTION_BASE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_DISTRIBUTION_BASE_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_DISTRIBUTION_BASE_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.FETCH_DISTRIBUTION_BASE_LOCATIONS_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(
      state,
      response,
      'location',
      'distribution_base_locations'
    )

    state.isCallingAPI = false
  },

  [types.ADD_DISTRIBUTION_BASE_LOCATION_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(
      state,
      response,
      'location',
      'distribution_base_locations'
    )
    helpers.pushResourceToSpecialResult(
      state,
      response,
      'location',
      'distribution_base_locations'
    )

    state.isCallingAPI = false
  },

  [types.UPDATE_DISTRIBUTION_BASE_LOCATION_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(
      state,
      response,
      'location',
      'distribution_base_locations'
    )

    state.isCallingAPI = false
  },

  [types.DELETE_DISTRIBUTION_BASE_LOCATION_SUCCESS](state, id) {
    helpers.removeOneResourceFromSpecialEntities(state, id, 'location')

    state.isCallingAPI = false
  },

  [types.FETCH_DISTRIBUTION_BASE_BRAND_SHIPS_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(
      state,
      response,
      'brandShip',
      'distribution_base_brand_ships'
    )

    state.isCallingAPI = false
  },

  [types.DELETE_DISTRIBUTION_BASE_BRAND_SHIP_SUCCESS](
    state,
    { model, response }
  ) {
    helpers.removeOneResourceFromSpecialEntities(state, model.id, 'brandShip')
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
