import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'add_on_item_prices',
  attributes: [
    'id',
    'add_on_item_id',
    'created_at',
    'updated_at',
    'price',
    'is_available',
    'variant_id',
    'limit_quantity',
    'price',
    'sku',
    'name',
    'original_price'
  ],
  editableAttributes: [
    'add_on_item_id',
    'variant_id',
    'limit_quantity',
    'new_price'
  ]
}

export default class AddOnItemPrice extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  apiBasePath(options = {}) {
    let result = `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}`

    result += `/add_on/item_prices`
    return result
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  get displayDiscountRate() {
    const discountRate = this.price / this.original_price

    if (discountRate >= 1) return

    let rateNumber = discountRate.toFixed(2).toString().split('.')[1]

    if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substring(0, 1)

    return `${rateNumber} ${I18n.t(
      'activerecord.attributes.product.discount_unit'
    )}`
  }
}
