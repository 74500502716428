import OrderPayment from '../../resource_models/order_payment'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new OrderPayment(state.entities[id]))
}

export const find = (state) => (id) => {
  return new OrderPayment(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const existingMethodNames = (state) => {
  return state.existingMethodNames
}

export const errors = (state) => {
  return state.errors
}

export const onsitePaymentRedirectPath = (state) => {
  return state.onsitePaymentRedirectPath
}

export const onsitePaymentRedirectParams = (state) => {
  return state.onsitePaymentRedirectParams
}
