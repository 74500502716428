import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'add_on_groups',
  attributes: [
    'id',
    'created_at',
    'updated_at',
    'name',
    'is_available',
    'products',
    'add_on_items',
    'add_on_products',
    'add_on_items_count',
    'attached_product_count'
  ],
  editableAttributes: ['name']
}

export default class AddOnGroup extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  apiBasePath(options = {}) {
    let result = `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}`

    result += `/add_on/groups`
    return result
  }

  attachProducts(productIds) {
    const requestBody = {
      data: {
        type: 'add_on_groups',
        attributes: {
          product_ids: productIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/products/attach`,
      requestBody
    )
  }

  detachProducts(productIds) {
    const requestBody = {
      data: {
        type: 'add_on_groups',
        attributes: {
          product_ids: productIds
        }
      }
    }

    return axios.delete(`${this.apiBasePath()}/${this.id}/products/detach`, {
      data: requestBody
    })
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  joinItems(productIds) {
    const requestBody = {
      data: {
        type: 'add_on_groups',
        attributes: {
          product_ids: productIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/items/join`,
      requestBody
    )
  }

  removeItems(productIds) {
    const requestBody = {
      data: {
        type: 'add_on_groups',
        attributes: {
          product_ids: productIds
        }
      }
    }

    return axios.delete(`${this.apiBasePath()}/${this.id}/items/remove`, {
      data: requestBody
    })
  }
}
