import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'return_authorization_reasons',
  attributes: ['id', 'name', 'is_active'],
  editableAttributes: ['name']
}

export default class ReturnAuthorizationReason extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }

  // extra methods or helpers here...
}
