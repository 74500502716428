import CategorySystem from '../../resource_models/category_system'
import Category from '../../resource_models/category'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new CategorySystem(state.entities[id]))
}

export const find = (state) => (id) => {
  return new CategorySystem(state.entities[id])
}

export const allCategories =
  (state) =>
  (options = {}) => {
    let categories = state.categoryResult.map(
      (id) => new Category(state.categoryEntities[id])
    )

    if (options.depth)
      categories = categories.filter(
        (category) => category.depth === options.depth
      )

    if (options.defaultUsages)
      categories = categories.filter((category) =>
        options.defaultUsages.includes(category.default_usage)
      )

    return categories
  }

export const categoriesByUsage = (state) => (usage) => {
  return allCategories(state)().filter(
    (category) => category.default_usage === usage
  )
}

export const childrenOf = (state) => (parent) => {
  return allCategories(state)().filter(
    (category) => Number(category.parent_id) === Number(parent.id)
  )
}

export const findCategory = (state) => (id) => {
  return new Category(state.categoryEntities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
