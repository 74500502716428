import OrderShipment from '../../resource_models/order_shipment'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new OrderShipment(state.entities[id]))
}

export const find = (state) => (id) => {
  return new OrderShipment(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const existingMethodNames = (state) => {
  return state.existingMethodNames
}

export const expressMapParams = (state) => {
  return state.expressMapParams
}

export const expressMapApiBase = (state) => {
  return state.expressMapApiBase
}

export const errors = (state) => {
  return state.errors
}
