export const FETCH_USER_BONUS_CREDIT_TRANSACTIONS_SUCCESS =
  'FETCH_USER_BONUS_CREDIT_TRANSACTIONS_SUCCESS'
export const GET_RELATED_USER_BONUS_CREDIT_TRANSACTIONS_SUCCESS =
  'GET_RELATED_USER_BONUS_CREDIT_TRANSACTIONS_SUCCESS'
export const GET_USER_BONUS_CREDIT_TRANSACTION_SUCCESS =
  'GET_USER_BONUS_CREDIT_TRANSACTION_SUCCESS'
export const ADD_USER_BONUS_CREDIT_TRANSACTION_SUCCESS =
  'ADD_USER_BONUS_CREDIT_TRANSACTION_SUCCESS'
export const UPDATE_USER_BONUS_CREDIT_TRANSACTION_SUCCESS =
  'UPDATE_USER_BONUS_CREDIT_TRANSACTION_SUCCESS'
export const DELETE_USER_BONUS_CREDIT_TRANSACTION_SUCCESS =
  'DELETE_USER_BONUS_CREDIT_TRANSACTION_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
