export const FETCH_INFO_LINKS_SUCCESS = 'FETCH_INFO_LINKS_SUCCESS'
export const GET_RELATED_INFO_LINKS_SUCCESS = 'GET_RELATED_INFO_LINKS_SUCCESS'
export const GET_INFO_LINK_SUCCESS = 'GET_INFO_LINK_SUCCESS'
export const ADD_INFO_LINK_SUCCESS = 'ADD_INFO_LINK_SUCCESS'
export const UPDATE_INFO_LINK_SUCCESS = 'UPDATE_INFO_LINK_SUCCESS'
export const DELETE_INFO_LINK_SUCCESS = 'DELETE_INFO_LINK_SUCCESS'
export const FETCH_INFO_LINK_PAGE_SLOTS_SUCCESS =
  'FETCH_INFO_LINK_PAGE_SLOTS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
