import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'slides',
  attributes: ['id', 'square', 'horizontal', 'vertical', 'config', 'position'],
  editableAttributes: ['config']
}

export default class Slide extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
