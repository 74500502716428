import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'return_authorizations',
  attributes: [
    'id',
    'number',
    'state',
    'note',
    'reason',
    'order',
    'order_id',
    'items',
    'reimbursements',
    'is_reimbursed',
    'total_amount',
    'created_at',
    'updated_at',
    'order_number',
    'order_billing_address',
    'admin_name'
  ],
  editableAttributes: ['note', 'reason_id', 'items', 'order_token']
}

export default class ReturnAuthorization extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  accept() {
    return axios.put(`${this.apiBasePath()}/${this.id}/accept`)
  }

  cancel() {
    return axios.put(`${this.apiBasePath()}/${this.id}/cancel`)
  }

  createReimbursement() {
    return axios.post(`${this.apiBasePath()}/${this.id}/create_reimbursement`)
  }

  // extra methods or helpers here...
  get hasReimbursements() {
    return this.reimbursements.length > 0
  }

  get isApplyByAdmin() {
    return !!this.admin_name
  }

  stateType() {
    return {
      'is-warning': this.state === 'pending',
      'is-success': this.state === 'authorized',
      'is-danger': this.state === 'canceled'
    }
  }
}
