import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promotional_articles',
  attributes: [
    'category_ids',
    'content',
    'cover',
    'mobile_cover',
    'created_at',
    'id',
    'meta_description',
    'meta_keywords',
    'meta_title',
    'published_at',
    'ended_at',
    'slug',
    'title',
    'view_count',
    'is_available',
    'is_age_restricted'
  ],
  editableAttributes: [
    'title',
    'content',
    'category_ids',
    'meta_title',
    'meta_keywords',
    'meta_description',
    'slug',
    'published_at',
    'ended_at',
    'uploaded_attachment_ids',
    'is_available',
    'is_age_restricted'
  ]
}

export default class PromotionalArticle extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    const FetchingDataOptionParams = FetchingDataOptionsService.call(options)
    const usageParams = options.usage ? `usage=${options.usage}` : null
    const skipTopOrderParams = options.skipTopOrder ? 'skip_top_order=1' : null

    const queryString = [
      FetchingDataOptionParams,
      usageParams,
      skipTopOrderParams
    ]
      .filter((string) => string)
      .join('&')

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  // extra methods or helpers here...

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }

  saveWithFormData(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }

  description(endOfSubstring = 70) {
    return (
      this.content.blocks
        .filter((block) => block.type === 'paragraph' || block.type === 'raw')
        .map((block) => {
          if (block.type === 'paragraph') {
            return block.data.text
          } else {
            return block.data.html
          }
        })
        .join('')
        // remove all html tags
        .replace(/<(.|\n)*?>/g, '')
        .substring(0, endOfSubstring)
    )
  }

  isLive() {
    if (!this.is_available) return false

    let timeCurrent = Date.now()
    let endedAt = this.ended_at || timeCurrent + 1

    return this.published_at < timeCurrent && timeCurrent < endedAt
  }
}
