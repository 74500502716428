import OddPayPaymentMethod from '@models/odd_pay/payment_method.js'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new OddPayPaymentMethod(state.entities[id]))
}

export const find = (state) => (id) => {
  return new OddPayPaymentMethod(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const forPaymentGateway = (state) => (id) => {
  return all(state).filter((method) => String(method.payment_gateway_id) === id)
}

export const usageScopeInfo = (state) => {
  return state.usageScopeInfo
}
