export const FETCH_ODD_PAY_INVOICES_SUCCESS = 'FETCH_ODD_PAY_INVOICES_SUCCESS'
export const GET_RELATED_ODD_PAY_INVOICES_SUCCESS =
  'GET_RELATED_ODD_PAY_INVOICES_SUCCESS'
export const GET_ODD_PAY_INVOICE_SUCCESS = 'GET_ODD_PAY_INVOICE_SUCCESS'
export const ADD_ODD_PAY_INVOICE_SUCCESS = 'ADD_ODD_PAY_INVOICE_SUCCESS'
export const UPDATE_ODD_PAY_INVOICE_SUCCESS = 'UPDATE_ODD_PAY_INVOICE_SUCCESS'
export const DELETE_ODD_PAY_INVOICE_SUCCESS = 'DELETE_ODD_PAY_INVOICE_SUCCESS'
export const GET_CURRENT_NORMAL_ODD_PAY_INVOICE_SUCCESS =
  'GET_CURRENT_NORMAL_ODD_PAY_INVOICE_SUCCESS'
export const GET_CURRENT_SUBSCRIPTION_ODD_PAY_INVOICE_SUCCESS =
  'GET_CURRENT_SUBSCRIPTION_ODD_PAY_INVOICE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
