import * as types from './mutation-types'
import ProductTag from '../../resource_models/product_tag'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    ProductTag.all(options)
      .then((response) => {
        commit(types.FETCH_PRODUCT_TAGS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    ProductTag.find(id)
      .then((response) => {
        commit(types.GET_PRODUCT_TAG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PRODUCT_TAG_SUCCESS, response)
        } else {
          commit(types.UPDATE_PRODUCT_TAG_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PRODUCT_TAG_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchProducts = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then((response) => {
        if (options.forSearch) {
          dispatch(
            'products/receiveResourcesFromRelationshipsForSearch',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch(
            'products/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        }
        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const removeProduct = ({ dispatch, commit }, { model, product }) => {
  commit(types.API_REQUEST_START, 'removeProduct')

  return new Promise((resolve, reject) => {
    model
      .removeProduct(product)
      .then((response) => {
        dispatch('products/removeResourceFromRelationships', product.id, {
          root: true
        })
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              product
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const bulkAssignProducts = (
  { dispatch, commit },
  { model, productIds }
) => {
  commit(types.API_REQUEST_START, 'bulkAssignProducts')

  return new Promise((resolve, reject) => {
    model
      .bulkAssignProducts(productIds)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'bulkAssignProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: bulkAssignProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productIds
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const bulkMoveProducts = ({ dispatch, commit }, { model, data }) => {
  commit(types.API_REQUEST_START, 'bulkMoveProducts')

  return new Promise((resolve, reject) => {
    model
      .bulkMoveProducts(data)
      .then((response) => {
        dispatch('products/removeResourcesFromRelationships', data.productIds, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'bulkMoveProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: bulkMoveProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              data
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const bulkRemoveProducts = (
  { dispatch, commit },
  { model, productIds }
) => {
  commit(types.API_REQUEST_START, 'bulkRemoveProducts')

  return new Promise((resolve, reject) => {
    model
      .bulkRemoveProducts(productIds)
      .then((response) => {
        dispatch('products/removeResourcesFromRelationships', productIds, {
          root: true
        })
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: bulkRemoveProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productIds
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleAvailability = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleAvailability')

  return new Promise((resolve, reject) => {
    model
      .toggleAvailability()
      .then((response) => {
        commit(types.UPDATE_PRODUCT_TAG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleAvailability,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PRODUCT_TAGS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PRODUCT_TAG_SUCCESS, response)

    resolve(response)
  })
}
