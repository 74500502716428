export const FETCH_ODD_PAY_PAYMENT_METHODS_SUCCESS =
  'FETCH_ODD_PAY_PAYMENT_METHODS_SUCCESS'
export const GET_RELATED_ODD_PAY_PAYMENT_METHODS_SUCCESS =
  'GET_RELATED_ODD_PAY_PAYMENT_METHODS_SUCCESS'
export const GET_ODD_PAY_PAYMENT_METHOD_SUCCESS =
  'GET_ODD_PAY_PAYMENT_METHOD_SUCCESS'
export const ADD_ODD_PAY_PAYMENT_METHOD_SUCCESS =
  'ADD_ODD_PAY_PAYMENT_METHOD_SUCCESS'
export const UPDATE_ODD_PAY_PAYMENT_METHOD_SUCCESS =
  'UPDATE_ODD_PAY_PAYMENT_METHOD_SUCCESS'
export const DELETE_ODD_PAY_PAYMENT_METHOD_SUCCESS =
  'DELETE_ODD_PAY_PAYMENT_METHOD_SUCCESS'

export const FETCH_ODD_PAY_PAYMENT_METHOD_USAGE_SCOPE_INFO_SUCCESS =
  'FETCH_ODD_PAY_PAYMENT_METHOD_USAGE_SCOPE_INFO_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
